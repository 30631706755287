import { useState } from "react";
import { loadConnectAndInitialize } from "@stripe/connect-js";
import {
  ConnectComponentsProvider,
  ConnectAccountManagement,
} from "@stripe/react-connect-js";
import { httpsCallable } from "firebase/functions";
import Layout from "../../../components/Layout/Layout";
import { functions } from "../../../services/firebase/firebase";

export default function AccountManagement() {
  const [stripeConnectInstance] = useState(() => {
    const fetchClientSecret = async () => {
      // Fetch the AccountSession client secret
      const getSession = httpsCallable<
        { component: string },
        { client_secret: string; error?: string }
      >(functions, "connectAccountSession");
      const { data } = await getSession({ component: "management" });
      if (data.error) {
        // Handle errors on the client side here
        console.log("An error occurred: ", data.error);
        return "error";
      } else {
        console.log(data.client_secret);
        return data.client_secret;
      }
    };
    return loadConnectAndInitialize({
      // This is your test publishable API key.
      publishableKey: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!,
      fetchClientSecret: fetchClientSecret,
      appearance: {
        overlays: "dialog",
        variables: {
          colorPrimary: "#625afa",
        },
      },
    });
  });

  return (
    <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
      <ConnectAccountManagement />
    </ConnectComponentsProvider>
  );
}
