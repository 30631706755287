import { Fragment, useContext, useEffect } from "react";
import { UserContext } from "../../App";
import LandingPage from "../../views/LandingPage/LandingPage";
import Spinner from "../Spinner/Spinner";
import { useNavigate } from "react-router-dom";

const Redirect = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/");
  }, []);
  return (
    <div>
      <Spinner />
    </div>
  );
};

type ProtectedRouteProps = {
  children?: React.ReactNode;
};

const ProtectedRoute = (props: ProtectedRouteProps) => {
  const user = useContext(UserContext);
  console.log(user);
  if (user?.uid) {
    return <Fragment>{props.children}</Fragment>;
  } else {
    return <Redirect />;
  }
};

export default ProtectedRoute;
