import { Link, useParams } from "react-router-dom";
import Layout from "../../components/Layout/Layout";
import PaymentHistory from "./views/PaymentHistory";
import { ReactNode, useEffect, useState } from "react";
import styles from "./proAccountDashboardStyles.module.css";
import AccountManagement from "./views/AccountManagement";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../services/firebase/firebase";
import { loadConnectAndInitialize } from "@stripe/connect-js";
import {
  ConnectAccountManagement,
  ConnectBalances,
  ConnectComponentsProvider,
} from "@stripe/react-connect-js";
import useUserData from "../../hooks/useUserData";
import { SkeletonPage } from "../../components/Skeleton/Skeleton";
import SignUpForPro from "./views/SignUpForPro/SignUpForPro";

type MyPage = {
  path: string;
  component: ReactNode;
  title: string;
  description: string;
};

const PageLink = (props: { data: MyPage }) => {
  return (
    <Link
      to={`/pro-account-dashboard/${props.data.path}`}
      className={styles.pageLink}
    >
      <h4 className={styles.header}>{props.data.title}</h4>
      <p className={styles.desc}>{props.data.description}</p>
    </Link>
  );
};

export default function ProAccountDashboard() {
  const userData = useUserData();
  const [loading, setLoading] = useState(true);
  const [stripeConnectInstance] = useState(() => {
    const fetchClientSecret = async () => {
      // Fetch the AccountSession client secret
      const getSession = httpsCallable<
        { component: string },
        { client_secret: string; error?: string }
      >(functions, "connectAccountSession");
      const { data } = await getSession({ component: "balances" });
      if (data.error) {
        // Handle errors on the client side here
        console.log("An error occurred: ", data.error);
        return "error";
      } else {
        console.log(data.client_secret);
        return data.client_secret;
      }
    };
    return loadConnectAndInitialize({
      // This is your test publishable API key.
      publishableKey: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!,
      fetchClientSecret: fetchClientSecret,
      appearance: {
        overlays: "dialog",
        variables: {
          colorPrimary: "#625afa",
        },
      },
    });
  });

  const { page } = useParams();
  const pages = [
    {
      path: "payment-history",
      component: <PaymentHistory />,
      title: "Payment History",
      description: "View your account's transaction history",
    },
    {
      path: "account-management",
      component: <AccountManagement />,
      title: "Account Management",
      description:
        "Manage linked bank accounts, edit personal & professional details",
    },
  ];
  const [currentPage, setCurrentPage] = useState<MyPage>();

  useEffect(() => {
    const current = pages.find((p) => p.path === page);
    setCurrentPage(current);
  }, [page]);

  useEffect(() => {
    if (userData) {
      setLoading(false);
    }
  }, [userData]);

  if (currentPage) {
    return (
      <Layout>
        <div className={styles.wrapper}>
          <div>
            <Link to="/pro-account-dashboard">Pro Dashboard</Link>
            {" > "}
            <Link to={`/pro-account-dashboard/${currentPage.path}`}>
              {currentPage.title}
            </Link>
          </div>
          <div>{currentPage.component}</div>
        </div>
      </Layout>
    );
  }

  if (loading) {
    return <SkeletonPage />;
  }

  if (userData?.isProUser) {
    return (
      <Layout>
        <div className={styles.wrapper}>
          <h2 className="mb20">TatMap Pro</h2>
          <section className="mb40">
            <h4 className="mb10">Balances</h4>
            <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
              <ConnectBalances />
            </ConnectComponentsProvider>
          </section>
          <section>
            <h4 className="mb10">Account Links</h4>
            <div className={styles.pageLinks}>
              {pages.map((p) => (
                <PageLink key={p.title} data={p} />
              ))}
            </div>
          </section>
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <SignUpForPro />
    </Layout>
  );
}
