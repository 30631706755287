import { Fragment, useContext, useEffect, useState } from "react";
import { TMUserContext, UserContext } from "../../../../App";
import { MyUser, SearchLocation, StudioLocation } from "../../../../types";
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  setDoc,
} from "firebase/firestore";
import { db, functions } from "../../../../services/firebase/firebase";
import { converter } from "../../../../utils";
import { httpsCallable } from "firebase/functions";
import Button from "../../../../components/Button/Button";
import NewLocationInput from "../../../../components/NewLocationInput/NewLocationInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import styles from "../../settingsStyles.module.css";
import LocationRadio from "../../../../components/LocationRadio/LocationRadio";

const StudioLocationSettings = () => {
  const session = useContext(UserContext);
  const { userData, setUserData } = useContext(TMUserContext);

  const [addHomeStudio, setAddHomeStudio] = useState(false);
  const [savingHomeStudio, setSavingHomeStudio] = useState(false);
  const [savingGuestStudio, setSavingGuestStudio] = useState(false);
  const [addGuestStudio, setAddGuestStudio] = useState(false);
  const [searchLocation, setSearchLocation] = useState<SearchLocation>();
  const [studioLocations, setStudioLocations] = useState<StudioLocation[]>();
  const [primaryStudio, setPrimaryStudio] = useState<StudioLocation>();
  const [newPrimaryStudio, setNewPrimaryStudio] = useState<StudioLocation>();
  const [guestStudios, setGuestStudios] = useState<StudioLocation[]>();
  const [newGuestStudio, setNewGuestStudio] = useState<StudioLocation>();

  const getStudioLocations = async (uid: string) => {
    const collectionRef = collection(
      db,
      "users",
      uid,
      "studioLocations"
    ).withConverter(converter<StudioLocation>());
    const snapshot = await getDocs(collectionRef);
    const locationsData = snapshot.docs.map((doc) => doc.data());
    setStudioLocations(
      locationsData.sort((a, b) => {
        return Number(b.isPrimary) - Number(a.isPrimary);
      })
    );
  };

  useEffect(() => {
    if (session) {
      getStudioLocations(session.uid);
    }
  }, []);

  useEffect(() => {
    if (studioLocations) {
      const primary = studioLocations.find((sl) => sl.isPrimary);
      setPrimaryStudio(primary);
      const guests = studioLocations.filter((sl) => !sl.isPrimary);
      setGuestStudios(guests);
    }
  }, [studioLocations]);

  const handleSaveGuestStudio = async () => {
    if (session && newGuestStudio) {
      setSavingGuestStudio(true);
      const docRef = doc(
        db,
        "users",
        session.uid,
        "studioLocations",
        newGuestStudio.googleId
      );
      await setDoc(docRef, { ...newGuestStudio, isPrimary: false });
      const getUserData = httpsCallable(functions, "getUserData");
      const results = await getUserData();
      const u = results.data as MyUser;
      setUserData(u || null);
      setNewGuestStudio(undefined);
      getStudioLocations(session.uid);
      setSavingGuestStudio(false);
      setAddGuestStudio(false);
    }
  };

  const handleSavePrimaryStudio = async () => {
    if (session && userData && newPrimaryStudio) {
      const newSearchLocation =
        typeof userData.searchLocation === "undefined" ||
        userData.searchLocation.googleId === primaryStudio?.googleId
          ? {
              googleId: newPrimaryStudio?.googleId,
              lat: newPrimaryStudio?.location.latitude,
              lng: newPrimaryStudio?.location.longitude,
            }
          : undefined;
      const docRef = doc(
        db,
        "users",
        session.uid,
        "studioLocations",
        newPrimaryStudio.googleId
      );
      await Promise.all([
        setDoc(docRef, { ...newPrimaryStudio, isPrimary: true }),
        ...(newSearchLocation
          ? [
              setDoc(
                doc(db, "users", session.uid),
                { searchLocation: newSearchLocation },
                { merge: true }
              ),
            ]
          : []),
        ...(primaryStudio
          ? [
              deleteDoc(
                doc(
                  db,
                  "users",
                  session.uid,
                  "studioLocations",
                  primaryStudio.docId!
                )
              ),
            ]
          : []),
      ]);
      setNewPrimaryStudio(undefined);
      getStudioLocations(session.uid);
      const getUserData = httpsCallable(functions, "getUserData");
      const results = await getUserData();
      const u = results.data as MyUser;
      setUserData(u || null);
      setAddHomeStudio(false);
    }
  };

  const deleteGuestStudio = async (studio: StudioLocation) => {
    console.log(studio);
    if (session && userData) {
      const newSearchLocation =
        userData.searchLocation?.googleId === studio.googleId
          ? {
              googleId: primaryStudio?.googleId,
              lat: primaryStudio?.location.latitude,
              lng: primaryStudio?.location.longitude,
            }
          : undefined;
      const docRef = doc(
        db,
        "users",
        session.uid,
        "studioLocations",
        studio.docId!
      );
      await Promise.all([
        deleteDoc(docRef),
        ...(newSearchLocation
          ? [
              setDoc(
                doc(db, "users", session.uid),
                {
                  searchLocation: newSearchLocation,
                },
                { merge: true }
              ),
            ]
          : []),
      ]);
      getStudioLocations(session.uid);
    }
  };

  useEffect(() => {
    if (session && userData?.searchLocation) {
      setSearchLocation(userData.searchLocation);
    }
  }, [session, userData]);

  const handleSaveSearchLocation = async () => {
    if (session) {
      const docRef = doc(db, "users", session.uid);
      await setDoc(docRef, { searchLocation: searchLocation }, { merge: true });
      const getUserData = httpsCallable(functions, "getUserData");
      const results = await getUserData();
      const u = results.data as MyUser;
      setUserData(u || null);
    }
  };

  return (
    <div>
      <div className={styles.header}>
        <h2>Studio Location</h2>
        <p className={styles.description}>
          Your full studio address will only be visible to clients with
          confirmed appointments. The city where your studio is located will be
          displayed publicly on your profile.
        </p>
      </div>
      <div className={styles.mb10}>
        <h4>Primary Studio</h4>
        <p className={styles.description}>
          This will be used as the default location when you create calendar
          openings and for appearing in search results. You can change which
          location will be used for search results in the section below.
        </p>
      </div>
      {primaryStudio && !addHomeStudio ? (
        <div className={styles.studioWrapper}>
          <div style={{ fontWeight: "bold", marginBottom: "10px" }}>
            {primaryStudio.text.mainText}{" "}
            {primaryStudio.text.secondaryText || null}
          </div>
          <Button
            onClick={() => {
              setAddHomeStudio(true);
            }}
          >
            Change
          </Button>
        </div>
      ) : null}
      {(addHomeStudio || typeof primaryStudio === "undefined") && (
        <div className={styles.studioWrapper}>
          <NewLocationInput
            onSelect={(loc) => {
              setNewPrimaryStudio(loc);
            }}
            value={newPrimaryStudio}
            types={["establishment", "street_address", "premise", "store"]}
            placeholder="Enter Address or Studio Name"
            label="New Studio Location"
          ></NewLocationInput>
          <div style={{ marginTop: "10px" }}>
            <Button
              onClick={handleSavePrimaryStudio}
              loading={savingHomeStudio}
              style={{ marginRight: "10px" }}
            >
              Save
            </Button>
            {primaryStudio && (
              <Button
                color="red"
                onClick={() => {
                  setAddHomeStudio(false);
                  setNewPrimaryStudio(undefined);
                }}
              >
                Cancel
              </Button>
            )}
          </div>
        </div>
      )}
      <h4 style={{ marginTop: "20px" }}>Guest Studios</h4>
      <p className={[styles.description, styles.mb10].join(" ")}>
        You can add up to two guest locations at a time, which will be available
        as options when creating calendar openings as well as for appearing in
        search results.
      </p>
      {guestStudios?.map((gs) => (
        <div key={gs.docId} className={styles.studioWrapper}>
          <div style={{ fontWeight: "bold", marginBottom: "10px" }}>
            {gs.text.mainText} {gs.text.secondaryText || null}
          </div>
          <Button
            onClick={() => {
              deleteGuestStudio(gs);
            }}
            color="red"
          >
            Delete
          </Button>
        </div>
      ))}
      {guestStudios && guestStudios.length < 2 && !addGuestStudio && (
        <Button
          onClick={() => {
            setAddGuestStudio(true);
          }}
        >
          <FontAwesomeIcon icon={faPlus} />
          Add Guest Studio
        </Button>
      )}
      {addGuestStudio && (
        <Fragment>
          <NewLocationInput
            onSelect={(loc) => {
              setNewGuestStudio(loc);
            }}
            value={newGuestStudio}
            types={["establishment", "street_address", "premise", "store"]}
            placeholder="Enter Address or Studio Name"
            label="Guest Studio Location"
          ></NewLocationInput>
          <Button onClick={handleSaveGuestStudio}>Save</Button>
          <Button
            onClick={() => {
              setNewGuestStudio(undefined);
              setAddGuestStudio(false);
            }}
          >
            Cancel
          </Button>
        </Fragment>
      )}
      <h4 style={{ marginTop: "20px" }}>Search Results</h4>
      <p className={[styles.description, styles.mb10].join(" ")}>
        Choose which location you would like to use for location-based searches.
        Keeping this up to date will help your profile and designs appear in the
        most relevant search results.
      </p>
      {studioLocations?.map((sl) => (
        <LocationRadio
          key={sl.docId}
          onChange={() => {
            setSearchLocation({
              googleId: sl.googleId,
              lat: sl.location.latitude,
              lng: sl.location.longitude,
            });
          }}
          label={sl.text.mainText}
          id={sl.googleId}
          checked={searchLocation?.googleId === sl.googleId}
          header={sl.isPrimary ? "Primary Studio" : "Guest Studio"}
          style={{ marginBottom: "10px" }}
        />
      ))}
      <Button
        onClick={handleSaveSearchLocation}
        disabled={
          searchLocation?.googleId === userData?.searchLocation?.googleId
        }
      >
        Save
      </Button>
    </div>
  );
};

export default StudioLocationSettings;
