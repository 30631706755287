import {
  Fragment,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import Calendar from "../../components/Calendar/Calendar";
import CalendarSidebar from "../CalendarSidebar/CalendarSidebar";
import styles from "./calendarPlusSidebarStyles.module.css";
import { Appointment, Opening, TMUser, TMUserWithImageURL } from "../../types";
import { converter, months, openingConverter } from "../../utils";
import Button from "../Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronCircleLeft,
  faChevronCircleRight,
} from "@fortawesome/free-solid-svg-icons";
import useUserData from "../../hooks/useUserData";
import { UserContext } from "../../App";
import DropdownButton from "../DropdownButton/DropdownButton";
import { SkeletonPage } from "../Skeleton/Skeleton";
import { faEye } from "@fortawesome/free-regular-svg-icons";

export const CalendarContext = createContext<TMUser | null>(null);

type CalendarPlusSidebarProps = {
  calendarOwner?: TMUser | TMUserWithImageURL;
  openings: Opening[];
  appointments?: Appointment[];
  requests?: Appointment[];
  reloadOpenings: () => void;
  reloadAppointments?: () => void;
  clientView?: boolean;
  asUser?: TMUser;
};

const CalendarPlusSidebar = (props: CalendarPlusSidebarProps) => {
  const today = new Date();
  const userData = useUserData();
  const [selectedDates, setSelectedDates] = useState<Date[]>([]);
  const [currentMonth, setCurrentMonth] = useState<number>(today.getMonth());
  const [currentYear, setCurrentYear] = useState<number>(today.getFullYear());
  const [loading, setLoading] = useState(true);
  const [view, setView] = useState<string>();
  const {
    openings,
    appointments,
    requests,
    reloadOpenings,
    reloadAppointments,
  } = props;

  useEffect(() => {
    if (userData) {
      setLoading(false);
      setView(userData.accountType);
    }
  }, [userData]);

  if (loading) {
    return <SkeletonPage />;
  }

  return (
    <CalendarContext.Provider value={props.calendarOwner || null}>
      <div className={styles.headerWrapper}>
        <div style={{ flex: "30%" }}></div>
        <div className={styles.monthWrapper} style={{ flex: "30%" }}>
          <Button
            noStyling
            className={styles.monthButton}
            onClick={() => {
              if (currentMonth === 0) {
                setCurrentMonth(11);
                setCurrentYear((v) => v - 1);
              } else {
                setCurrentMonth((v) => v - 1);
              }
            }}
          >
            <FontAwesomeIcon icon={faChevronCircleLeft} />
          </Button>
          <h2>
            {months[currentMonth].toLowerCase()} {currentYear}
          </h2>
          <Button
            noStyling
            className={styles.monthButton}
            onClick={() => {
              if (currentMonth === 11) {
                setCurrentMonth(0);
                setCurrentYear((v) => v + 1);
              } else {
                setCurrentMonth((v) => v + 1);
              }
            }}
          >
            <FontAwesomeIcon icon={faChevronCircleRight} />
          </Button>
        </div>
        <div
          style={{
            flex: "30%",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          {userData?.accountType === "artist" && !props.clientView && (
            <DropdownButton
              selected={view || "artist"}
              options={["artist", "client"]}
              onChange={(v) => {
                setView(v);
              }}
              thin
              color="gray"
              direction="left"
            ></DropdownButton>
          )}
        </div>
      </div>
      <div className={styles.wrapper}>
        <div className={styles.cal}>
          <Calendar
            appointments={view === "client" ? [] : appointments}
            requests={view === "client" ? [] : requests}
            openings={openings}
            selectedDates={selectedDates}
            onChange={(newDates) => {
              setSelectedDates(newDates);
            }}
            onChangeMonth={() => {}}
            currentMonth={currentMonth}
            currentYear={currentYear}
          ></Calendar>
        </div>
        <div className={styles.sidebar}>
          <CalendarSidebar
            handleAppointmentsReload={reloadAppointments}
            handleOpeningsReload={reloadOpenings}
            openings={openings}
            appointments={appointments}
            requests={requests}
            selectedDates={selectedDates}
            view={view}
            clientView={props.clientView}
            currentMonth={currentMonth}
            currentYear={currentYear}
            onRemoveDate={(d: Date) => {
              setSelectedDates((current) =>
                current.filter(
                  (currentDate) =>
                    currentDate.getMonth() !== d.getMonth() ||
                    currentDate.getDate() !== d.getDate() ||
                    currentDate.getFullYear() !== d.getFullYear()
                )
              );
            }}
            asUser={props.asUser}
          ></CalendarSidebar>
        </div>
      </div>
    </CalendarContext.Provider>
  );
};

export default CalendarPlusSidebar;
