import React, { useEffect, useRef, useState } from "react";
import styles from "./timePickerStyles.module.css";
import e from "express";

type DropDownColumnProps = {
  values: string[];
  selectedVal: string;
  onClick: (v: string) => void;
  onMouseDown: () => void;
};

const DropDownColumn = (props: DropDownColumnProps) => {
  return (
    <ul className={styles.dropDownColumn} tabIndex={0}>
      {props.values.map((v) => (
        <li
          className={[
            styles.numButton,
            props.selectedVal === v ? styles.selected : null,
          ].join(" ")}
          key={v}
          onClick={(e: React.MouseEvent<HTMLLIElement>) => {
            e.stopPropagation();
            props.onClick(v);
          }}
          onMouseDown={(e: React.MouseEvent<HTMLLIElement>) => {
            e.preventDefault();
            e.stopPropagation();
            props.onMouseDown();
          }}
        >
          {v}
        </li>
      ))}
    </ul>
  );
};

export type Time = {
  hour: string;
  minute: string;
  ampm: string;
};

type TimePickerProps = {
  time: Time;
  onChange: (t: Time) => void;
};

const TimePicker = (props: TimePickerProps) => {
  const hourRef = useRef<HTMLInputElement | null>(null);
  const minuteRef = useRef<HTMLInputElement | null>(null);
  const ampmRef = useRef<HTMLInputElement | null>(null);

  const [hour, setHour] = useState<string>(props.time.hour || "hh");
  const [minute, setMinute] = useState<string>(props.time.minute || "mm");
  const [ampm, setAMPM] = useState<string>(props.time.ampm || "aa");
  const [showDropDown, setShowDropDown] = useState(false);
  const [pseudoFocus, setPseudoFocus] = useState(false);
  const [errorWarning, setErrorWarning] = useState(false);
  const [hourComplete, setHourComplete] = useState(false);
  const [minuteComplete, setMinuteComplete] = useState(false);
  const [ampmComplete, setAMPMComplete] = useState(false);
  const [currentSelection, setCurrentSelection] = useState<
    string | undefined
  >();

  const checkTimeIsValid = () =>
    hour !== "hh" && minute !== "mm" && ampm !== "aa" ? true : false;

  const containsOnlyDigits = (str: string) => /^\d+$/.test(str);

  useEffect(() => {
    props.onChange({ hour, minute, ampm });
    if (errorWarning) {
      if (checkTimeIsValid()) setErrorWarning(false);
    }
  }, [hour, minute, ampm]);

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setPseudoFocus(true);
    setShowDropDown(true);
    e.currentTarget.select();
  };

  const handleClick = (e: React.MouseEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();
    e.currentTarget.select();
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    e.preventDefault();
    setPseudoFocus(false);
    setShowDropDown(false);
    if (!checkTimeIsValid()) {
      setErrorWarning(true);
    }
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
  };

  const handleHourKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const newChar = e.key;
    if (newChar !== "Tab") e.preventDefault();
    if (newChar === "Backspace") {
      setHour("hh");
    }
    if (containsOnlyDigits(newChar)) {
      if (hour !== "01" && newChar !== "0") {
        setHour(`0${newChar}`);
        if (newChar !== "1") {
          minuteRef.current?.focus();
        }
      } else if (hour === "01") {
        if (["0", "1", "2"].includes(newChar)) {
          setHour(`1${newChar}`);
          minuteRef.current?.focus();
        } else {
          setHour(`0${newChar}`);
          minuteRef.current?.focus();
        }
      }
    }
  };

  const handleMinuteKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const newChar = e.key;
    if (newChar !== "Tab") e.preventDefault();
    if (newChar === "Backspace") {
      setMinute("mm");
    }
    if (containsOnlyDigits(newChar)) {
      if (minute === "mm" && parseInt(newChar) < 6) {
        setMinute(`0${newChar}`);
        if (parseInt(newChar) > 5) ampmRef.current?.focus();
      } else if (minute[0] === "0" && parseInt(minute[1]) < 6) {
        setMinute(`${minute[1]}${newChar}`);
        ampmRef.current?.focus();
      } else {
        setMinute(`0${newChar}`);
      }
    }
  };

  const handleAMPMKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const newChar = e.key;
    if (newChar !== "Tab") e.preventDefault();
    if (newChar === "Backspace") {
      setAMPM("aa");
    }

    if (newChar.toLowerCase() === "a") {
      setAMPM("AM");
    }
    if (newChar.toLowerCase() === "p") {
      setAMPM("PM");
    }
  };

  return (
    <div
      className={[
        styles.wrapper,
        pseudoFocus ? styles.pseudoFocus : null,
        errorWarning ? styles.errorWarning : null,
      ].join(" ")}
      onClick={(e: React.MouseEvent<HTMLDivElement>) => {
        console.log(e);
        hourRef.current?.focus();
      }}
    >
      <input
        ref={hourRef}
        value={hour}
        className={[
          styles.input,
          hour === "hh" ? styles.placeholder : null,
        ].join(" ")}
        onFocus={handleFocus}
        onClick={handleClick}
        onBlur={handleBlur}
        onChange={handleChange}
        onKeyDown={handleHourKeyDown}
      ></input>
      <div className={styles.colon}>:</div>
      <input
        ref={minuteRef}
        value={minute}
        onFocus={handleFocus}
        onClick={handleClick}
        onChange={handleChange}
        onKeyDown={handleMinuteKeyDown}
        onBlur={handleBlur}
        className={[
          styles.input,
          minute === "mm" ? styles.placeholder : null,
        ].join(" ")}
      ></input>
      <div style={{ width: "5px" }}></div>
      <input
        ref={ampmRef}
        value={ampm}
        onFocus={handleFocus}
        onClick={handleClick}
        onChange={handleChange}
        onBlur={handleBlur}
        onKeyDown={handleAMPMKeyDown}
        className={[
          styles.input,
          ampm === "aa" ? styles.placeholder : null,
        ].join(" ")}
      ></input>
      {showDropDown ? (
        <div className={styles.dropDownWrapper}>
          <DropDownColumn
            values={[
              "12",
              "01",
              "02",
              "03",
              "04",
              "05",
              "06",
              "07",
              "08",
              "09",
              "10",
              "11",
            ]}
            selectedVal={hour}
            onClick={(v) => setHour(v)}
            onMouseDown={() => {
              hourRef.current?.focus();
            }}
          />
          <DropDownColumn
            values={[
              "00",
              "05",
              "10",
              "15",
              "20",
              "25",
              "30",
              "35",
              "40",
              "45",
              "50",
              "55",
            ]}
            selectedVal={minute}
            onClick={(v) => setMinute(v)}
            onMouseDown={() => {
              minuteRef.current?.focus();
            }}
          />
          <DropDownColumn
            values={["AM", "PM"]}
            selectedVal={ampm}
            onClick={(v) => {
              setAMPM(v);
              if (hour !== "hh" && minute !== "mm") setShowDropDown(false);
            }}
            onMouseDown={() => {
              ampmRef.current?.focus();
            }}
          />
        </div>
      ) : null}
    </div>
  );
};

export default TimePicker;
