import { useState } from "react";
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../services/firebase/firebase";

const DeleteStripeAccount = () => {
  const [accountNum, setAccountNum] = useState("");
  const handleSubmit = () => {
    const deleteAccount = httpsCallable<
      { accountId: string },
      { id: string; object: string; deleted: boolean }
    >(functions, "deleteStripeAccount");
    deleteAccount({ accountId: accountNum }).then((response) => {
      console.log(JSON.stringify(response.data));
    });
  };
  return (
    <div>
      <Input
        value={accountNum}
        onChange={(v) => {
          setAccountNum(v);
        }}
      ></Input>
      <Button onClick={handleSubmit}>Delete</Button>
    </div>
  );
};

export default DeleteStripeAccount;
