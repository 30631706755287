import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Opening } from "../../types";
import { datesAreSameDay, days, fetchOpenings, sortByDate } from "../../utils";
import Button from "../Button/Button";
import Calendar from "../Calendar/Calendar";
import PopUp from "../PopUp/PopUp";
import styles from "./openingsPopUpStyles.module.css";
import { useEffect, useRef, useState } from "react";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";

type OpeningButtonProps = {
  data: Opening;
  onClick: () => void;
};

const OpeningButton = (props: OpeningButtonProps) => {
  return (
    <Button
      noStyling
      onClick={() => {
        props.onClick();
      }}
      className={styles.openingButton}
    >
      <div className="flexRowCenter">
        <h3 className="mr5">{`${parseInt(props.data.startTime.hour)}:${
          props.data.startTime.minute
        } ${props.data.startTime.ampm}`}</h3>
        <div>{`${days[props.data.date.getDay()].substring(0, 3)} ${
          props.data.date.getMonth() + 1
        }/${props.data.date.getDate()}/${props.data.date.getFullYear()}`}</div>
      </div>
      <div style={{ fontWeight: "normal" }}>
        <FontAwesomeIcon icon={faLocationDot} className={"mr10"} />
        {props.data.location.text.mainText}{" "}
        {props.data.location.text.secondaryText}
      </div>
    </Button>
  );
};

type OpeningsPopUpProps = {
  handleExit: () => void;
  handleSelect: (d: Opening) => void;
  artistId: string;
};

const OpeningsPopUp = (props: OpeningsPopUpProps) => {
  const today = new Date();
  const [openings, setOpenings] = useState<Opening[]>([]);
  const [selectedDate, setSelectedDate] = useState<Date[]>([]);
  const [selectedOpenings, setSelectedOpenings] = useState<Opening[]>([]);

  useEffect(() => {
    fetchOpenings(props.artistId).then((posts) => {
      setOpenings(posts);
    });
  }, []);

  useEffect(() => {
    if (selectedDate.length > 0) {
      const filteredOpenings = openings.filter((o) =>
        datesAreSameDay(o.date, selectedDate[0])
      );
      setSelectedOpenings(filteredOpenings);
    } else {
      setSelectedOpenings(openings);
    }
  }, [selectedDate, openings]);

  return (
    <PopUp
      handleExit={props.handleExit}
      style={{ justifyContent: "flex-start" }}
    >
      <h2 className={styles.header}>Available Openings</h2>
      <div>
        <Calendar
          selectedDates={selectedDate}
          onChange={(v) => {
            console.log(v);
            if (v.length > 1) {
              setSelectedDate([v[1]]);
            } else {
              setSelectedDate(v);
            }
          }}
          onChangeMonth={(m) => {
            console.log(m);
            setSelectedDate([]);
          }}
          openings={openings || []}
          small
        />
      </div>
      <div className={styles.openingsWrapper}>
        {selectedOpenings.length > 0 ? (
          selectedOpenings.map((d) => (
            <OpeningButton
              key={d.docId}
              data={d}
              onClick={() => {
                props.handleSelect(d);
                props.handleExit();
              }}
            />
          ))
        ) : (
          <div className={styles.noOpeningsText}>
            {openings.length === 0
              ? "This artist currently has no openings."
              : "No openings for the selected dates"}
          </div>
        )}
      </div>
    </PopUp>
  );
};

export default OpeningsPopUp;
