import {
  faAdd,
  faArrowRightLong,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fragment, useContext, useEffect, useState } from "react";
import { Appointment, Opening, TMUser } from "../../types";
import {
  datesAreSameDay,
  days,
  fetchImageUrl,
  fetchUser,
  formattedDateString,
  formattedDayName,
  months,
  sortByDate,
  sortDatesByDate,
} from "../../utils";
import Button from "../Button/Button";
import styles from "./calendarSidebarStyles.module.css";
import CreateNewOpening from "../CreateNewOpening/CreateNewOpening";
import { deleteDoc, doc } from "firebase/firestore";
import { db } from "../../services/firebase/firebase";
import { useNavigate } from "react-router-dom";
import ProfileImage from "../ProfileImage/ProfileImage";
import Anchor from "../Anchor/Anchor";
import AppointmentStatus from "../AppointmentStatus/AppointmentStatus";
import SelectionBubble from "../SelectionBubble/SelectionBubble";
import { UserContext } from "../../App";
import { CalendarContext } from "../CalendarPlusSidebar/CalendarPlusSidebar";
import { CalendarBanner } from "../../views/AccountDashboard/components/CalendarWidget/CalendarWidget";

type PreviewProps = {
  onClick?: () => void;
  children?: React.ReactNode;
  type?: string;
};

const Preview = (props: PreviewProps) => {
  return (
    <button
      onClick={props.onClick}
      className={[
        styles.preview,
        props.type === "opening" ? styles.openingPreview : null,
      ].join(" ")}
    >
      {props.children}
    </button>
  );
};

type AppointmentPreviewProps = {
  data: Appointment;
  expanded?: boolean;
  clientView?: boolean;
};

export const AppointmentPreview = (props: AppointmentPreviewProps) => {
  const user = useContext(UserContext);
  const { data } = props;
  const [clientData, setClientData] = useState<TMUser>();
  const [clientImageUrl, setClientImageUrl] = useState("");
  const [otherUser, setOtherUser] = useState<TMUser>();
  const [otherUserImageUrl, setOtherUserImageUrl] = useState("");
  const [isClientAppointment, setIsClientAppointment] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    fetchUser(props.data.clientId).then((data) => {
      setClientData(data);
      fetchImageUrl(data.imgLocation).then((url) => {
        setClientImageUrl(url);
      });
    });
  }, []);

  const getData = async (clientAppt: boolean) => {
    const otherUser = await fetchUser(
      clientAppt ? data.artistId : data.clientId
    );
    setOtherUser(otherUser);
    const url = await fetchImageUrl(otherUser.imgLocation);
    setOtherUserImageUrl(url);
  };

  useEffect(() => {
    const clientAppt = user?.uid === data.clientId;
    setIsClientAppointment(clientAppt);
    getData(clientAppt);
  }, [user, data]);

  return (
    <Preview
      onClick={() => {
        navigate(`/appointment/${data.docId}`);
      }}
    >
      <div className={styles.appointmentType}>
        {isClientAppointment ? "Client Appointment" : "Artist Appointment"}
      </div>
      <div className={[styles.appointmentPreviewWrapper, "mb5"].join(" ")}>
        <div className={styles.leftPreviewWrapper}>
          <div>{`${formattedDayName(data.date)} ${formattedDateString(
            data.date
          )}`}</div>
          <h3>{`${parseInt(data.startTime.hour)}:${data.startTime.minute} ${
            data.startTime.ampm
          }`}</h3>
        </div>
        <div className={styles.rightPreviewWrapper}>
          <div className={styles.otherUser}>
            {isClientAppointment ? "Artist" : "Client"}
          </div>
          <div className={styles.clientWrapper}>
            <ProfileImage
              imgLocation={otherUserImageUrl}
              size="xxxs"
              style={{ marginRight: "5px" }}
              href={`/u/${clientData?.username}`}
            />
            <div>
              <h5>
                {otherUser?.firstName} {otherUser?.lastName}
              </h5>
              <Anchor
                onClick={() => {
                  navigate(`/u/${otherUser?.username}`);
                }}
                style={{ fontSize: ".8em" }}
              >
                @{otherUser?.username}
              </Anchor>
            </div>
          </div>
        </div>
      </div>
      <AppointmentStatus status={data.status} />
    </Preview>
  );
};

type AppointmentRequestsProps = {
  data: Appointment[];
  numDatesSelected: number;
  handleReload?: () => void;
  clientView?: boolean;
  style?: React.CSSProperties;
};

const AppointmentRequests = (props: AppointmentRequestsProps) => {
  return (
    <div style={props.style}>
      <h3 style={{ marginBottom: "5px" }}>
        requests {props.data.length > 0 && `(${props.data.length})`}
      </h3>
      {props.data.length === 0 ? (
        <div style={{ margin: "20px 0", color: "rgb(100,100,100)" }}>
          No requests for the selected date
          {props.numDatesSelected === 1 ? "" : "s"}.
        </div>
      ) : (
        <div className={styles.previewsWrapper}>
          {props.data.map((o, i) => (
            <AppointmentPreview
              key={i}
              data={o}
              clientView={props.clientView}
            ></AppointmentPreview>
          ))}
        </div>
      )}
    </div>
  );
};

type OpeningPreviewProps = {
  data: Opening;
  onClick: () => void;
  onDelete: () => void;
  expanded?: boolean;
  clientView?: boolean;
  artistUsername?: string;
};

export const OpeningPreview = (props: OpeningPreviewProps) => {
  const navigate = useNavigate();
  const [deleting, setDeleting] = useState(false);
  const handleDelete = async () => {
    await deleteDoc(
      doc(db, "users", props.data.artistId, "openings", props.data.docId)
    );
  };

  return (
    <Preview type="opening">
      <div>
        <div>{`${formattedDayName(props.data.date)} ${formattedDateString(
          props.data.date
        )}`}</div>
        <h3>{`${parseInt(props.data.startTime.hour)}:${
          props.data.startTime.minute
        } ${props.data.startTime.ampm}`}</h3>
      </div>
      <div style={{ fontWeight: "normal", color: "rgb(100,100,100)" }}>
        <FontAwesomeIcon icon={faLocationDot} />{" "}
        {props.data.location.text.mainText}{" "}
        {props.data.location.text.secondaryText || ""}
      </div>
      {props.clientView ? (
        <Anchor
          onClick={() => {
            navigate(
              `/booking-request/${props.artistUsername}/o/${props.data.docId}`
            );
          }}
          style={{ marginTop: "5px" }}
        >
          BOOK NOW <FontAwesomeIcon icon={faArrowRightLong} />
        </Anchor>
      ) : (
        <Anchor
          onClick={() => {
            setDeleting(true);
            handleDelete().then(() => {
              setDeleting(false);
              props.onDelete();
            });
          }}
          className={styles.deleteButton}
        >
          DELETE
        </Anchor>
      )}
    </Preview>
  );
};

type OpeningsProps = {
  data: Opening[];
  numDatesSelected: number;
  handleReload: () => void;
  handleAddOpening: () => void;
  clientView?: boolean;
  style?: React.CSSProperties;
  adminView?: boolean;
};

const Openings = (props: OpeningsProps) => {
  const user = useContext(UserContext);
  const calendarOwner = useContext(CalendarContext);
  console.log(user);
  console.log(calendarOwner);
  return (
    <div style={props.style}>
      <div
        style={{ display: "flex", alignItems: "center", marginBottom: "5px" }}
      >
        <h3 style={{ marginRight: "5px" }}>
          openings {props.data.length > 0 && `(${props.data.length})`}
        </h3>
        {(user?.uid === calendarOwner?.docId || props.adminView) && (
          <Button onClick={props.handleAddOpening} color="gray" thin>
            <FontAwesomeIcon icon={faAdd}></FontAwesomeIcon> Create Opening
          </Button>
        )}
      </div>
      {props.data.length === 0 ? (
        <div>
          No openings for the selected date
          {props.numDatesSelected === 1 ? "" : "s"}.
        </div>
      ) : (
        <div className={styles.previewsWrapper}>
          {props.data.map((o, i) => (
            <OpeningPreview
              onDelete={props.handleReload}
              artistUsername={calendarOwner?.username}
              key={i}
              data={o}
              onClick={() => {}}
              clientView={props.clientView}
            ></OpeningPreview>
          ))}
        </div>
      )}
    </div>
  );
};

type AppointmentsProps = {
  data: Appointment[];
  numDatesSelected: number;
  style?: React.CSSProperties;
};

const Appointments = (props: AppointmentsProps) => {
  return (
    <div style={{ ...props.style }}>
      <h3 style={{ marginBottom: "5px" }}>
        appointments {props.data.length > 0 && `(${props.data.length})`}
      </h3>
      {props.data.length === 0 ? (
        <div style={{ margin: "20px 0" }}>
          No appointments for the selected date
          {props.numDatesSelected === 1 ? "" : "s"}.
        </div>
      ) : (
        props.data.map((appt, i) => (
          <AppointmentPreview key={appt.docId} data={appt}></AppointmentPreview>
        ))
      )}
    </div>
  );
};

type CalendarSidebarProps = {
  selectedDates: Date[];
  openings: Opening[];
  appointments?: Appointment[];
  requests?: Appointment[];
  handleOpeningsReload: () => void;
  handleAppointmentsReload?: () => void;
  clientView?: boolean;
  onRemoveDate: (d: Date) => void;
  currentMonth: number;
  currentYear: number;
  asUser?: TMUser;
  view?: string;
};

const CalendarSidebar = (props: CalendarSidebarProps) => {
  const [addOpening, setAddOpening] = useState(false);
  const [selectedOpenings, setSelectedOpenings] = useState<Opening[]>([]);
  const [selectedAppointments, setSelectedAppointments] = useState<
    Appointment[]
  >([]);
  const [selectedRequests, setSelectedRequests] = useState<Appointment[]>([]);

  useEffect(() => {
    const l: Opening[] = [];
    if (props.selectedDates.length === 0) {
      l.push(
        ...props.openings.filter(
          (r) =>
            r.date.getMonth() === props.currentMonth &&
            r.date.getFullYear() === props.currentYear
        )
      );
    } else {
      props.selectedDates.forEach((d) => {
        l.push(...props.openings!.filter((o) => datesAreSameDay(o.date, d)));
      });
    }
    setSelectedOpenings(sortByDate(l));
  }, [props.selectedDates, props.openings, props.currentMonth]);

  const prepareApptData = (data: Appointment[]) => {
    const l: Appointment[] = [];
    if (props.selectedDates.length === 0) {
      l.push(
        ...data.filter(
          (d) =>
            d.date.getMonth() === props.currentMonth &&
            d.date.getFullYear() === props.currentYear
        )
      );
    } else {
      props.selectedDates.forEach((d) => {
        l.push(...data.filter((o) => datesAreSameDay(o.date, d)));
      });
    }
    return l;
  };

  useEffect(() => {
    if (props.appointments) {
      const l: Appointment[] = prepareApptData(props.appointments);
      setSelectedAppointments(l);
    }
  }, [props.selectedDates, props.appointments, props.currentMonth]);

  useEffect(() => {
    if (props.requests) {
      const l: Appointment[] = prepareApptData(props.requests);
      setSelectedRequests(l);
    }
  }, [props.selectedDates, props.requests, props.currentMonth]);

  return (
    <Fragment>
      {addOpening && (
        <CreateNewOpening
          selectedDates={props.selectedDates}
          onExit={() => {
            setAddOpening(false);
          }}
          onCreate={props.handleOpeningsReload}
          onRemoveDate={props.onRemoveDate}
          asUser={props.asUser}
        />
      )}
      {!addOpening && (
        <Fragment>
          <div className={styles.headerWrapper}>
            {props.selectedDates.length === 0 ? (
              <div style={{ display: "flex" }}>
                <SelectionBubble readonly>{`${months[props.currentMonth]} ${
                  props.currentYear
                }`}</SelectionBubble>
              </div>
            ) : (
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {sortDatesByDate(props.selectedDates).map((d) => (
                  <SelectionBubble
                    onRemove={() => {
                      props.onRemoveDate(d);
                    }}
                    key={`${months[d.getMonth()]} ${d.getDate()}`}
                  >
                    {`${months[d.getMonth()]} ${d.getDate()}`}
                  </SelectionBubble>
                ))}
              </div>
            )}
          </div>
          {!props.clientView && (
            <CalendarBanner
              view={props.view}
              appointments={selectedAppointments}
              requests={selectedRequests}
              openings={selectedOpenings}
              justifyContent="space-around"
              className={[styles.banner, "mb20"].join(" ")}
            />
          )}
          <div className={styles.bodyWrapper}>
            {!props.clientView && selectedRequests.length > 0 && (
              <AppointmentRequests
                data={selectedRequests}
                handleReload={props.handleAppointmentsReload}
                numDatesSelected={props.selectedDates.length}
                style={{ marginBottom: "30px" }}
              />
            )}
            {!props.clientView && selectedAppointments.length > 0 && (
              <Appointments
                data={selectedAppointments}
                numDatesSelected={props.selectedDates.length}
                style={{ marginBottom: "30px" }}
              />
            )}
            {(props.clientView || props.view === "artist") && (
              <Openings
                data={selectedOpenings}
                numDatesSelected={props.selectedDates.length}
                handleReload={props.handleOpeningsReload}
                handleAddOpening={() => {
                  setAddOpening(true);
                }}
                clientView={props.clientView}
                style={{ marginBottom: "30px" }}
                adminView={props.asUser ? true : false}
              />
            )}
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default CalendarSidebar;
