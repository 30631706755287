import { Dispatch, SetStateAction, createContext } from "react";
import { useState, useEffect } from "react";
import "./App.css";
import LandingPage from "./views/LandingPage/LandingPage";
import AccountDashboard from "./views/AccountDashboard/AccountDashboard";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import PostFlash from "./views/PostFlash/PostFlash";
import FlashDetail from "./views/FlashDetail/FlashDetail";
import { onAuthStateChanged, User } from "firebase/auth";
import { auth, functions } from "./services/firebase/firebase";
import Discover from "./views/Discover/Discover";
import ArtistPage from "./views/ArtistPage/ArtistPage";
import Settings from "./views/Settings/Settings";
import CalendarSettings from "./views/CalendarSettings/CalendarSettings";
import BookingRequest from "./views/BookingRequest/BookingRequest";
import AppointmentDetail from "./views/AppointmentDetail/AppointmentDetail";
import { fetchImageUrl, fetchUser, fetchUserWithImageUrl } from "./utils";
import { MyUser, TMUser, TMUserWithImageURL } from "./types";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import PostTattoo from "./views/PostTattoo/PostTattoo";
import TattooDetail from "./views/TattooDetail/TattooDetail";
import EmailVerification from "./views/EmailVerification/EmailVerification";
import Spinner from "./components/Spinner/Spinner";
import CheckoutPage from "./views/CheckoutPage/CheckoutPage";
import Return from "./views/Return/Return";
import { httpsCallable } from "firebase/functions";
import DeleteStripeAccount from "./views/DeleteStripeAccount/DeleteStripeAccount";
import ConnectOnboarding from "./views/ConnectOnboarding/ConnectOnboarding";
import ProAccountDashboard from "./views/ProAccountDashboard/ProAccountDashboard";
import PayDeposit from "./views/PayDeposit/PayDeposit";
import DepositPaid from "./views/DepositPaid/DepositPaid";
import Onboarding from "./views/Onboarding/Onboarding";

export const UserContext = createContext<User | null>(null);

export const TMUserContext = createContext<{
  userData: MyUser | null;
  setUserData: Dispatch<SetStateAction<MyUser | null>>;
}>({ userData: null, setUserData: () => {} });

const router = createBrowserRouter([
  {
    path: "/",
    element: <LandingPage />,
  },
  {
    path: "/home",
    element: (
      <ProtectedRoute>
        <AccountDashboard />
      </ProtectedRoute>
    ),
  },
  { path: "/discover", element: <Discover /> },
  { path: "/discover/:type", element: <Discover /> },
  {
    path: "/new-flash-post",
    element: (
      <ProtectedRoute>
        <PostFlash />
      </ProtectedRoute>
    ),
  },
  { path: "/u/:artistId/flash/:docId", element: <FlashDetail /> },
  { path: "/u/:artistId/tattoo/:docId", element: <TattooDetail /> },
  { path: "/u/:artistId", element: <ArtistPage /> },
  {
    path: "/settings/:page?/:subpage?",
    element: (
      <ProtectedRoute>
        <Settings />
      </ProtectedRoute>
    ),
  },
  {
    path: "/calendar",
    element: (
      <ProtectedRoute>
        <CalendarSettings />
      </ProtectedRoute>
    ),
  },
  { path: "/booking-request/:artistId", element: <BookingRequest /> },
  {
    path: "/booking-request/:artistId/:idType/:additionalId",
    element: <BookingRequest />,
  },
  {
    path: "/appointment/:appointmentId",
    element: <AppointmentDetail />,
  },
  {
    path: "/tattoo-post",
    element: <PostTattoo />,
  },
  {
    path: "/auth/usermgmt",
    element: <EmailVerification />,
  },
  {
    path: "/checkout",
    element: <CheckoutPage />,
  },
  {
    path: "/return",
    element: <Return />,
  },
  // Stripe Connect onboarding when user creates TatMap Pro account
  {
    path: "/pro-account-onboarding",
    element: <ConnectOnboarding />,
  },
  // TatMap onboarding right after account creation
  {
    path: "/onboarding",
    element: <Onboarding />,
  },
  {
    path: "/onboarding/:page",
    element: <Onboarding />,
  },
  // Embedded Stripe Connect components for TatMap Pro users.
  {
    path: "/pro-account-dashboard",
    element: <ProAccountDashboard />,
  },
  {
    path: "/pro-account-dashboard/:page",
    element: <ProAccountDashboard />,
  },
  {
    path: "/delete-connect-account",
    element: <DeleteStripeAccount />,
  },
  // Checkout page for paying tattoo deposit
  {
    path: "/pay-deposit/:appointmentId",
    element: <PayDeposit />,
  },
  // Confirmation screen after client pays deposit for tattoo appointment
  {
    path: "/deposit-paid",
    element: <DepositPaid />,
  },
]);

function App() {
  const [sessionLoaded, setSessionLoaded] = useState(false);
  const [session, setSession] = useState<User | undefined>(
    auth.currentUser || undefined
  );
  const [userData, setUserData] = useState<MyUser | null>(null);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      console.log("Auth State Change");
      console.log(`user: ${user}`);
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/auth.user
        setSession(user);
      } else {
        setSession(undefined);
      }
      setSessionLoaded(true);
    });
  }, []);

  useEffect(() => {
    if (session) {
      const getUserData = httpsCallable(functions, "getUserData");
      getUserData()
        .then((result) => {
          const data = result.data as MyUser;
          setUserData(data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [session]);

  if (!sessionLoaded) {
    return <Spinner />;
  }

  return (
    <UserContext.Provider value={session || null}>
      <TMUserContext.Provider value={{ userData, setUserData }}>
        <RouterProvider router={router} />
      </TMUserContext.Provider>
    </UserContext.Provider>
  );
}

export default App;
