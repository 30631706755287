import { Fragment, useEffect, useState } from "react";
import { FlashPost, StudioLocation, TMUser } from "../../types";
import ProfileImage from "../ProfileImage/ProfileImage";
import styles from "./artistPreviewStyles.module.css";
import { converter } from "../../utils";
import FlashCard from "../FlashCard/FlashCard";
import Anchor from "../Anchor/Anchor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  query,
} from "firebase/firestore";
import { db } from "../../services/firebase/firebase";
import BooksOpenOrClosed from "../BooksOpenOrClosed/BooksOpenOrClosed";

type ArtistPreviewProps = {
  data: TMUser;
  style?: React.CSSProperties;
};

const ArtistPreview = (props: ArtistPreviewProps) => {
  const [posts, setPosts] = useState<FlashPost[]>([]);
  const [searchLocation, setSearchLocation] = useState<StudioLocation>();
  const [loadingSearchLocation, setLoadingSearchLocation] = useState(true);

  const getPosts = async () => {
    const collectionRef = collection(db, "users", props.data.docId, "flash");
    const q = query(collectionRef, limit(5)).withConverter(
      converter<FlashPost>()
    );
    const querySnapshot = await getDocs(q);
    const queryData = querySnapshot.docs.map((doc) => doc.data());
    setPosts(queryData);
  };

  const getSearchLocation = async () => {
    if (props.data.searchLocation) {
      console.log("this called");
      const docRef = doc(
        db,
        "users",
        props.data.docId,
        "studioLocations",
        props.data.searchLocation.googleId
      ).withConverter(converter<StudioLocation>());
      const studioSnapshot = await getDoc(docRef);
      const studio = studioSnapshot.data();
      console.log(studio);
      setSearchLocation(studio);
      setLoadingSearchLocation(false);
    }
  };

  useEffect(() => {
    getPosts();
    getSearchLocation();
  }, []);

  return (
    <div className={styles.wrapper} style={props.style}>
      <div className={styles.artistWrapper}>
        <ProfileImage
          imgLocation={props.data.imgLocation}
          href={`/u/${props.data.username}`}
        />
        <h4>
          {props.data.firstName} {props.data.lastName}
        </h4>
        <Anchor
          style={{ fontSize: "0.8em", color: "rgb(100,100,100)" }}
          className={["mb5"].join(" ")}
        >
          @{props.data.username}
        </Anchor>
        {searchLocation && (
          <div className={[styles.location, "mb5"].join(" ")}>
            <FontAwesomeIcon
              icon={faLocationDot}
              style={{ marginRight: "5px" }}
            />
            <span>
              {`${searchLocation.locality}, ${searchLocation.administrativeAreaLevel1?.longText}`}
            </span>
          </div>
        )}
        <BooksOpenOrClosed open={props.data.booksOpen || false} />
      </div>
      <div className={styles.postsWrapper}>
        {posts.map((p) => (
          <FlashCard
            key={p.docId}
            flashId={p.docId}
            artistId={p.artistId}
            data={p}
            size="s"
            style={{ marginRight: "5px" }}
          />
        ))}
      </div>
      <div className={styles.postsOverlay} />
    </div>
  );
};

export default ArtistPreview;
