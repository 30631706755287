import React, { ForwardRefRenderFunction, RefObject, forwardRef } from "react";
import styles from "./radioButtonStyles.module.css";

type RadioButtonProps = {
  onChange: (id: string) => void;
  checked: boolean;
  id: string;
  label: string;
  style?: React.CSSProperties;
  className?: string;
};

const RadioButton = forwardRef<HTMLInputElement, RadioButtonProps>(
  (props: RadioButtonProps, ref) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      console.log(event.currentTarget.id);
      props.onChange(event.currentTarget.id);
    };
    return (
      <div style={props.style} className={props.className}>
        <input
          type="radio"
          id={props.id}
          className={styles.hidden}
          onChange={handleChange}
          checked={props.checked}
          ref={ref}
        ></input>
        <label htmlFor={props.id} className={styles.label}>
          <div
            className={[
              styles.dot,
              props.checked ? styles.checked : styles.unchecked,
            ].join(" ")}
          />
          <div
            className={
              props.checked ? styles.checkedText : styles.uncheckedText
            }
          >
            {props.label}
          </div>
        </label>
      </div>
    );
  }
);

export default RadioButton;
