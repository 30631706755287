import { Fragment } from "react/jsx-runtime";
import dashboardStyles from "../../accountDashboardStyles.module.css";
import styles from "./calendarWidgetStyles.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";
import { Appointment, MyUser, Opening } from "../../../../types";
import DropdownButton from "../../../../components/DropdownButton/DropdownButton";
import { useContext, useEffect, useState } from "react";
import { AppointmentPreview } from "../../../../components/CalendarSidebar/CalendarSidebar";
import { TMUserContext, UserContext } from "../../../../App";
import {
  fetchAppointments,
  fetchClientAppointments,
  fetchOpenings,
} from "../../../../utils";

type CalendarBannerProps = {
  view?: string;
  justifyContent?: "space-between" | "space-around" | "center" | "stretch";
  appointments?: Appointment[];
  requests?: Appointment[];
  openings?: Opening[];
  className?: string;
};

export const CalendarBanner = (props: CalendarBannerProps) => {
  const { view, appointments, requests, openings, justifyContent } = props;
  return (
    <div
      className={[
        styles.calendarBanner,
        view === "client" && styles.clientView,
        props.className,
      ].join(" ")}
      style={{ justifyContent: justifyContent }}
    >
      <div className={styles.calendarBannerElement}>
        <h1>{appointments?.length || 0}</h1>
        <p className={styles.calendarBannerDesc}>Appointments</p>
      </div>
      <div className={styles.calendarBannerElement}>
        <h1>{requests?.length || 0}</h1>
        <p className={styles.calendarBannerDesc}>Requests</p>
      </div>
      {view === "artist" && (
        <div className={styles.calendarBannerElement}>
          <h1>{openings?.length || 0}</h1>
          <p className={styles.calendarBannerDesc}>Openings</p>
        </div>
      )}
    </div>
  );
};

type CalendarWidgetProps = {};

const CalendarWidget = (props: CalendarWidgetProps) => {
  const session = useContext(UserContext);
  const { userData } = useContext(TMUserContext);
  const [calendarView, setCalendarView] = useState<string | undefined>(
    userData?.accountType
  );
  const [appointments, setAppointments] = useState<Appointment[]>();
  const [requests, setRequests] = useState<Appointment[]>();
  const [openings, setOpenings] = useState<Opening[]>();

  useEffect(() => {
    if (userData) {
      setCalendarView(userData.accountType);
    }
  }, [userData]);

  useEffect(() => {
    if (session && calendarView) {
      console.log("");
      if (calendarView === "artist") {
        fetchAppointments(session.uid).then((response) => {
          setAppointments(
            response.filter(
              (appt) =>
                appt.status === "confirmed" || appt.status === "accepted"
            )
          );
          setRequests(response.filter((appt) => appt.status === "pending"));
        });
        fetchOpenings(session.uid).then((response) => {
          setOpenings(response);
        });
      }
      if (calendarView === "client") {
        fetchClientAppointments(session.uid).then((response) => {
          setAppointments(
            response.filter(
              (appt) =>
                appt.status === "confirmed" || appt.status === "accepted"
            )
          );
          setRequests(response.filter((appt) => appt.status === "pending"));
        });
      }
    }
  }, [session, calendarView]);

  return (
    <Fragment>
      <div className={[styles.headerWrapper, "mb20"].join(" ")}>
        <h3 className="mb10 mr10">
          <FontAwesomeIcon icon={faCalendarDays} className="mr5" /> calendar
        </h3>
        {userData?.accountType === "artist" && (
          <DropdownButton
            color="gray"
            thin
            selected={calendarView || "artist"}
            options={["artist", "client"]}
            onChange={(newVal) => {
              setCalendarView(newVal);
            }}
          ></DropdownButton>
        )}
      </div>
      <CalendarBanner
        view={calendarView}
        appointments={appointments}
        requests={requests}
        openings={openings}
        className="mb40"
      />
      <h5 className="mb10">UPCOMING APPOINTMENTS</h5>
      <div className={[styles.appointmentsWrapper, "mb20"].join(" ")}>
        {appointments && appointments.length > 0 ? (
          appointments?.map((appt) => (
            <AppointmentPreview key={appt.docId} data={appt} />
          ))
        ) : (
          <p
            className={[
              dashboardStyles.nothingHere,
              dashboardStyles.nothingHereText,
            ].join(" ")}
          >
            No Appointments Scheduled
          </p>
        )}
      </div>
      <h5 className="mb10">APPOINTMENT REQUESTS</h5>
      <div className={[styles.appointmentsWrapper, "mb20"].join(" ")}>
        {requests && requests.length > 0 ? (
          requests?.map((req) => (
            <AppointmentPreview key={req.docId} data={req} />
          ))
        ) : (
          <p
            className={[
              dashboardStyles.nothingHere,
              dashboardStyles.nothingHereText,
            ].join(" ")}
          >
            No requests
          </p>
        )}
      </div>
    </Fragment>
  );
};

export default CalendarWidget;
