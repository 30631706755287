import { Fragment, useContext, useEffect, useState } from "react";
import { useStripeConnect } from "../../hooks/useStripeConnect";
import {
  ConnectAccountManagement,
  ConnectAccountOnboarding,
  ConnectComponentsProvider,
} from "@stripe/react-connect-js";
import { TMUserContext } from "../../App";
import Layout from "../../components/Layout/Layout";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../services/firebase/firebase";
import Button from "../../components/Button/Button";

type MyConnectAccount = {
  charges_enabled: boolean;
  payouts_enabled: boolean;
  details_submitted: boolean;
};

const ConnectOnboarding = () => {
  const { userData } = useContext(TMUserContext);
  const [accountCreatePending, setAccountCreatePending] = useState(false);
  const [onboardingExited, setOnboardingExited] = useState(false);
  const [error, setError] = useState(false);
  const [connectedAccountId, setConnectedAccountId] = useState<string>();
  const [connectAccountData, setConnectAccountData] =
    useState<MyConnectAccount>();
  const stripeConnectInstance = useStripeConnect(connectedAccountId);

  useEffect(() => {
    const getConnectAccount = httpsCallable<undefined, MyConnectAccount>(
      functions,
      "getConnectAccount"
    );
    getConnectAccount().then((result) => {
      setConnectAccountData(result.data);
      console.log(result.data);
    });
  }, []);

  useEffect(() => {
    if (userData?.stripeConnectId) {
      setConnectedAccountId(userData.stripeConnectId);
    }
  }, [userData]);

  return (
    <Layout>
      {stripeConnectInstance && (
        <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
          <ConnectAccountOnboarding onExit={() => setOnboardingExited(true)} />
        </ConnectComponentsProvider>
      )}

      {error && <p className="error">Something went wrong!</p>}
      {(connectedAccountId || accountCreatePending || onboardingExited) && (
        <div className="dev-callout">
          {accountCreatePending && <p>Creating a connected account...</p>}
          {onboardingExited && (
            <div className="flexRowCenter" style={{ justifyContent: "center" }}>
              <Button href="/manage-pro-account">Manage Account</Button>
            </div>
          )}
        </div>
      )}
    </Layout>
  );
};

export default ConnectOnboarding;
