import { useContext, useEffect, useState } from "react";
import Layout from "../../components/Layout/Layout";
import { Appointment, Opening, TMUser } from "../../types";
import { fetchAppointments, fetchOpenings } from "../../utils";
import CalendarPlusSidebar from "../../components/CalendarPlusSidebar/CalendarPlusSidebar";
import { TMUserContext, UserContext } from "../../App";

type CalendarSettingsProps = {
  clientView?: boolean;
  asUser?: TMUser;
};

const CalendarSettings = (props: CalendarSettingsProps) => {
  const session = useContext(UserContext);
  const { userData } = useContext(TMUserContext);
  const [openings, setOpenings] = useState<Opening[]>([]);
  const [appointments, setAppointments] = useState<Appointment[]>([]);

  useEffect(() => {
    if (session) {
      fetchAppointments(session.uid).then((data) => {
        setAppointments(data);
        console.log(data);
      });
      fetchOpenings(session.uid).then((data) => {
        setOpenings(data);
      });
    }
  }, []);

  return (
    <Layout>
      <CalendarPlusSidebar
        calendarOwner={userData || undefined}
        openings={openings}
        appointments={appointments.filter((a) => a.status === "accepted")}
        requests={appointments.filter((a) => a.status === "pending")}
        reloadOpenings={() => {
          if (session) {
            fetchOpenings(session.uid).then((data) => {
              setOpenings(data);
            });
          }
        }}
        asUser={props.asUser}
      />
    </Layout>
  );
};

export default CalendarSettings;
